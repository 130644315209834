import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';
import ScrollspyNav from 'react-scrollspy-nav';
import { NikkeSkill } from '../../../modules/nikke/common/components/nikke-skill';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NikkeGuidesMana: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Mana guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_mana.webp"
            alt="Guide"
          />
        </div>
        <div className="page-details">
          <h1>Mana Guide & Review</h1>
          <h2>A guide & review for Mana in NIKKE: Goddess of Victory.</h2>
          <p>
            Last updated: <strong>19/Jan/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Mana" />
              <div className="employee-container for-nikke">
                <NikkeCharacter mode="icon" slug="mana" enablePopover />
              </div>
              <h5>Can you give a brief summary about this unit?</h5>
              <p>
                A fair unit, not broken in the sense that she deals a lot of
                damage, but in the sense that she shifts the meta by shuffling
                the current meta comps a bit. To start, she has several unique
                abilities, such as auto-revive that does not require using Burst
                Skill, continuous team healing that can trigger Crown's and
                Nero's passives, and charge speed boost to the unit with the
                longest charging time. This makes her niche but very versatile
                in that niche. For example, if we are talking about PvE, her
                charge speed is useful for SBS, and in PvP, her healing and
                revive can make for some artistic stall comps.
              </p>
              <p>
                In terms of damage, it is not impressive, but decent enough to
                be relevant against Wind-weak bosses, pairing together with SBS
                and potentially replacing Alice for mobile users or those who
                cannot fast-tap Alice. We are not sure yet if she will have
                hidden synergy with the Wind collab units (speculative) coming
                up next. She can be used in teams that need continuous healing
                or revive, replacing Rapunzel and especially with Crown in team.
                Furthermore, her role as a B3 allows for a 1-1-3 setup, offering
                you the choice of using her as an in-burst or as an off-burst
                DPS/support.
              </p>
              <p>
                Lastly, her PvP potential is huge. In the past, the only unit
                who was able to continuously heal Nero was Rapunzel. With her,
                this changed. Mana actually does a similar job as Rapunzel does
                when it comes to activating Nero's passive. Not only that, you
                can pair her together, and Mana's charge speed boost will allow
                Rapunzel to heal faster too! There is a lot of creative setups
                you can come up with here, such as triple sacs with Rosanna,
                etc. Surprise us with your inventions!
              </p>
              <h5>Should I pull this unit?</h5>
              <p>
                Good question. Honestly, yes. There are lots of things that you
                can do with her, in bed and outside bed. However, you might want
                to be wary that, while she does free Alice from the captives of
                SBS, it can be hard to justify whether it is worth the slot.
                This answer will change depending on what element the raid is,
                what mechanics the boss will have, and so on. Still, raiders had
                better keep one copy on hand in case she is used.
              </p>
              <p>
                From a PvP perspective, the answer becomes more positive. This
                is the only game mode where her burst damage is impactful, and
                the only game mode where her healing will be game-changing, and
                the only game mode where revives are OPAF. It is the only unit
                in-game that can revive anyone without Bursting and with 96% HP
                too! Fighting units who snipe your DPS? No problemo. Your tank
                dies? No problemo. Yeah, she does not even need to Burst
                herself. In fact, she might fare better as an off-burst B3.
              </p>
              <p>
                Nevertheless, if you are a beginner or short on gems, the
                solution becomes more complicated. It is generally a good idea
                to have units such as her in your roster for security, but
                saying she is more valuable than whatever units are coming up
                next, especially EVA 2 units, is not an easy question to answer.
                Sure, you can wait until her banner is about to end, but we are
                not even certain if the collab will be next season or the patch
                after that. You have to decide this one, chief. We advise you
                read the entire guide before you make up your mind.
              </p>
              <p>
                And no, she is not worth mileage nor dupes. Save those gems and
                ticket(s) for broken units, seasonal units, and above all
                Pilgrims.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Kit Analysis" />
              <p>
                Mana wields an Assault Rifle that takes 1.67s to reload, which
                is quite long but compensated with increased base burst gen, and
                deals 15.07% damage per shot, which is also higher than standard
                AR but damage ratio is still far from ideal. She generates ~0.25
                energy per shot, and if S2 is fully maxed and active, then
                ~0.426 per shot. This makes her the fourth highest burst gen AR
                character after Scarlet, Grave, and Asuka.
              </p>
              <h5>Skill 1</h5>
              <blockquote>
                <p>■ Activates at the start of the battle. Affects self.</p>
                <p>
                  Metal y: ATK ▲ 58.08% continuously. Activates once per battle.
                </p>
                <p>
                  ■ Activates when in Metal y status after landing 10 normal
                  attacks. Affects all allies.
                </p>
                <p>Recovers 2.04% of caster's final Max HP.</p>
                <p>
                  ■ Activates if the caster is in Metal y status when an ally is
                  out of action. Affects 1 incapacitated ally unit(s) with the
                  highest ATK (except caster).
                </p>
                <p>Resurrect with 96% HP.</p>
                <p>■ Activates when an ally is out of action. Affects self.</p>
                <p>Removes Metal y.</p>
              </blockquote>
              <p>
                Mana grants herself <strong>Metal γ</strong>, a buff that grants
                her increased ATK and the ability to revive a fallen ally once
                per battle by consuming it. As long as this buff is present (and
                not removed by extraordinary means like Rosanna's dispel), she
                will also continually heal all allies every 10 shots (~0.83s in
                PvE or ~1s in PvP). Note that an Attacker's HP is lower than
                that of Defender or Supporter, so the perceived effect would be
                weaker than if it were cast by the other two roles.
              </p>
              <blockquote>
                <h6>
                  <FontAwesomeIcon icon={faLightbulb} width="18" /> We Lowkey
                  Expected This From Flora
                </h6>
                <p>
                  Each instance of healing counts as a separate recovery effect.
                  This allows her to stack Nero's passive, activate Crown's
                  passive, trigger Asuka's ATK buff, and any other skills that
                  rely on "recovery taking place”.
                </p>
              </blockquote>
              <p>
                There is one problem with this <strong>Metal γ</strong>, though,
                and that is after it is consumed to revive a fallen unit, Mana
                no longer enjoys her ATK buff, nor does she heal the team.
                Unless you have a special utilization for revive that brings
                more good than harm, this scenario is to be avoided in PvE
                content as it significantly affects her damage (even as an
                off-burst support because every drop of damage matters).
                However, do keep in mind that her base damage ratios are not
                particularly impressive, so losing the buff may not be as
                detrimental as it initially seems.
              </p>
              <p>
                To be fully clear, <strong>58.08% ATK buff</strong> is not a
                joke and it is of the highest virtue to preserve it for as long
                as possible, especially if she is one of the primary B3. With
                careful planning and mitigation strategies, you can avoid the
                death of any unit (not always but strive for it).
              </p>
              <p>
                Additionally, the revival prioritizes units with the highest
                ATK. In the event multiple units die at the same time, the one
                with the highest ATK will be revived. The revival is also
                instant.
              </p>
              <h5>Skill 2</h5>
              <blockquote>
                <p>■ Activates at the start of the battle. Affects self.</p>
                <p>Metal o: Burst Gauge filling speed ▲ 70.4% continuously.</p>
                <p>
                  ■ Activates when entering Full Burst in Metal o status.
                  Affects self.
                </p>
                <p>Attack Damage ▲ 21.12% for 10 sec.</p>
                <p>ATK ▲ 63.36% for 10 sec.</p>
                <p>Removes Metal O.</p>
                <p>
                  ■ Activates when entering Full Burst. Affects I ally unit(s)
                  with the longest basic Charge Time.
                </p>
                <p>Charge Time ▼ 0.18 sec for 10 sec.</p>
                <p>
                  ■ Activates if the caster has cast Burst Skill before Full
                  Burst ends. Affects self.
                </p>
                <p>Metal o: Burst Gauge filling speed ▲ 70.4% continuously.</p>
              </blockquote>
              <p>
                Upon entering battle, Mana gives herself{' '}
                <strong>Metal σ</strong>, a buff that increases her burst gen by
                XX% and that can be consumed upon entering Full Burst to
                increase her <strong>ATK and Attack Damage</strong>. It can only
                be replenished by using her Burst Skill (recovered at the end of
                that Full Burst). This means she will lose{' '}
                <strong>Metal σ</strong> after the first Full Burst and will not
                regain it back if used as an off-burst B3.
              </p>
              <Alert variant="primary">
                <p>
                  <strong>Metal σ</strong> buffs affect her Burst Skill's damage
                  because sustained damage uses live ATK value. And yes, if you
                  think her kit is weird in the sense that, outside the first
                  rotation, her <strong>Metal σ</strong> buff is only active
                  within the other B3's Full Burst, you are correct! In fact, we
                  wonder if this was an oversight by ShiftUp. Basically, the{' '}
                  <strong>Metal σ</strong> buff will not overlap with her Burst
                  Skill's activation except on the first rotation or if you
                  Burst only with her.
                </p>
              </Alert>
              <StaticImage
                src="../../../images/nikke/reviews/mana_guide_2.webp"
                alt="Kit"
              />
              <p>
                <i>Image credits to discord users: cnydo and kelvinpizza</i>
              </p>
              <p>
                Now to what makes her powerful: Charge Time reduction,{' '}
                <strong>to the ally with the highest base charge time</strong>.
                SBS (Scarlet: Black Shadow) is the only character who benefits
                from this the most, but it is applicable to any character,
                including Alice, who desperately needs those 2 Charge Speed OLs
                to reach 100% breakpoint.
              </p>
              <blockquote>
                <h6>
                  <FontAwesomeIcon icon={faLightbulb} width="18" /> Taste the
                  Instantaneous
                </h6>
                <p>
                  Beginners without access to good OL can combine Rental Alice,
                  which has 10/10/10 skills, and Mana to obtain 100% Charge
                  Speed. A temporary solution for the time being until you can
                  get your hand on advanced gear. More info on Skill
                  Investments.
                </p>
              </blockquote>
              <p>
                Why is Scarlet: Black Shadow her best pairing? Well, that is
                because her Charge Time is already so low that 0.18s is 60% of
                it! What this means is that SBS will need 60% less time to Full
                Charge, dealing more damage and activating her S1 more often.
                Great! As for other units, they can still benefit from this
                0.18s reduction, but since most have a charge time of between
                1-1.5s, the time reduction is only about 12-18%.
              </p>
              <p>
                Note that if you use Scarlet: Black Shadow, make sure no one
                else in the team has a higher base Charge Time, including Alice,
                Red Hood, Rapunzel, DWife, Rouge, or anyone that has Charge,
                literally.
              </p>
              <Alert variant="primary">
                <p>
                  If two or more units have the same base charge time, whoever
                  is leftmost is prioritized.
                </p>
              </Alert>
              <p>
                Additionally, Mana gains increased burst gen from 0.25 per shot
                up to 0.426 per shot <strong>when Metal σ is active</strong>,
                but it's more of a PvP thing if any. This is likely to
                compensate for the fact that, if you use her to buff certain key
                units, you cannot add other RL/SRs as they will steal her buff.
                This often comes at the cost of not having utility units to spam
                with for burst gen.
              </p>
              <h5>Burst Skill</h5>
              <blockquote>
                <p>■ Affects self.</p>
                <p>Sustained Damage ▲ 52.8% for 10 sec.</p>
                <p>■ Affects 1 enemy unit(s) nearest to the crosshair.</p>
                <p>
                  Deals 396% of final ATK as sustained damage every 1 sec for 10
                  sec
                </p>
              </blockquote>
              <p>
                Strong sustained damage but hindered by the lack of notable
                offensive buffs (except for when she has{' '}
                <strong>Metal σ</strong>, which has awkward acquirement
                conditions). She also gains{' '}
                <strong>▲ Sustained Damage 52.8%</strong>, which is quite insane
                but also diluted by other effects related to Damage
                Amplification. In total, she deals just shy of 4000% (+2000%
                from Sustained Damage buff) to one enemy, which is not bad in
                today's standards, but it's not really destructive by any means.
              </p>
              <Alert variant="primary">
                <p>
                  Sustained Damage uses live ATK value, which updates
                  dynamically. Hence, it benefits from <strong>Metal σ</strong>{' '}
                  and external buffs such as Maxwell's and Helm's, and obviously{' '}
                  <strong>Metal γ</strong> unless deactivated (or dispelled).
                </p>
              </Alert>
              <p>
                That being said, <strong>58.08% ATK</strong> is not mediocre,
                but you will need more than that nowadays, so even with{' '}
                <strong>Metal γ</strong>, you are bound to treat her to some ATK
                OL and ELE.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Usage Analysis and Tier List" />
              <h5>
                Campaign (LD) - <strong className="a">A</strong> | Campaign (HD)
                - <strong className="a">A</strong>
              </h5>
              <p>
                As a B3, she fails to deliver the DPS you need to clear the
                stage. But, as a non-burst healer, she’s definitely better than
                average. She can also trigger Crown’s S2 passive over and over
                (until she loses Metal γ, of course). Think of her as Rapunzel
                2.0 who provides a charge speed buff to one ally. With her
                charge speed buff, she assists SBS in dealing more damage.
              </p>
              <p>
                However, to buff SBS means you cannot add another SR or RL unit.
                Otherwise, Mana will instead provide the charge speed buff to
                them. As a result, you are prevented from using burst gen units
                like Red Hood. Therefore, Mana’s ideal usage might just be
                alongside Alice. She can remove Alice’s charge speed requirement
                from OL gear, which is a good thing for players who do not have
                enough resources to make an ideal Alice, and allow beginners to
                experience her greatness early on. This is still niche,
                nevertheless, and Mana is more strictly a healer only for
                Campaign.
              </p>
              <h5>
                Bossing - <strong className="s">S</strong>
              </h5>
              <p>
                While it is true she doesn't offer much in terms of personal
                damage, her noble profession as SBS's slave is something to
                admire. Without SBS, her impact is not so strong, so that is
                what carries her to S rank—that niche role where she is only
                replaceable by Alice, and not every time can she do that.
                Sometimes, it's better to let Alice go to join other teams. We
                also don't know yet if they will release new Wind units that can
                also synergize with Mana (we have a collab to look forward to).
                Mana also comes with revive, which is useful situationally, and
                can also speed up other units other than SBS albeit with much
                lesser impact. She can also continually heal the team, allowing
                them to survive longer boss fights. She can also fill as an
                off-burst B3 if her supportive buffs are desired. She also holds
                a special role for beginners, who don't have Alice built and
                only have access to rental Alice. A properly built Mana can
                allow them to reach 100% Charge Speed on Rental Alice.
              </p>
              <p>
                A short tldr would be that Mana is expected to perform well in
                Wind Weak Raids and is also one of the top units to use versus
                Kraken. You can stage 9 with the SBS-Mana combo on Kraken’s
                Anomaly Interception!
              </p>
              <h5>
                PVP - <strong className="ss">SS</strong>
              </h5>
              <p>
                Mana’s main involvement in PvP revolves around her revival
                skill. With decent burst gen, instantaneous revive and constant
                healing, she can fit in some stall teams. Her revive can also
                counteract assassins/wipers in some way (against Rosanna too,
                but only if there are 2 other Attackers or units with more ATK).
                Literally Rapunzel 2.0 but this time she does not even need to
                burst! To recap some general knowledge about PvP, there are 3
                important points for building a good PvP team:
              </p>
              <ul>
                <li>Protect your Position 1 Nikke.</li>
                <li>Protect your DPS.</li>
                <li>Have enough burst generation.</li>
              </ul>
              <p>
                Mana, if used as an off burst unit (just put her in P4/P5), can
                fulfill the first requirement easily, acting like a
                pseudo-alternative to Biscuit. If used in some unique ways, she
                may also fulfill the second requirement. And to top it all off,
                her burst gen is not outright terrible! Hence, she is a very
                strong unit to use in Arena, and can definitely see use in SP
                Arena. However, she can be a bit niche. We will talk more about
                her actual use cases below in PVP Team Comps.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Investments" />
              <h5>Gear Investments</h5>
              <p>
                Mana scales best with ATK & ELE. If you want to build her
                offense, aim for these, or just don't build her at all if you
                only care about her supportive effects.
              </p>
              <ul>
                <li>
                  <strong>Essential:</strong>
                </li>
                <li>
                  <strong>Ideal:</strong> 4× ELE, 4× ATK
                </li>
                <li>
                  <strong>Passable:</strong>
                </li>
                <li>
                  <strong>Priority:</strong> Medium
                </li>
              </ul>
              <h5>Skill Investments</h5>
              <p>
                Mana's S2 is the most important part of her skillset. It is what
                obtains her a spot in the meta. Focus on upgrading that first,
                and only care about S1 and Burst leveling for damage.
              </p>
              <ul>
                <li>
                  <strong>Skill 1: 4~7</strong>
                </li>
                <ul>
                  <li>Increased team healing and personal offense.</li>
                </ul>
                <li>
                  <strong>Skill 2: 7-10</strong>
                </li>
                <ul>
                  <li>
                    Higher levels = higher charge speed bonus. It is not that
                    important unless you pair her with SBS/Alice or other Charge
                    Speed sensitive characters.
                  </li>
                  <li>
                    When pairing with SBS, every level suddenly becomes more
                    valuable. Level this high enough to make her relevant and
                    comparable to Alice.
                  </li>
                  <li>
                    When pairing with Alice (10/x/10) without Charge Speed OL
                    and outside help aside from Mana, Level 5 will grant Alice
                    100% Charge Speed.
                  </li>
                </ul>
                <li>
                  <strong>Burst Skill:4~7</strong>
                </li>
                <ul>
                  <li>
                    Strictly for personal damage. Might as well ignore if you
                    plan to leave her unbuilt or use her off-burst.
                  </li>
                </ul>
              </ul>
              <h5>Suggested Progression Path</h5>
              <div className="nikke-skills-tips">
                <div className="nikke-skills-row header">
                  <div className="column character">&nbsp;</div>
                  <div className="column suggestions pve">PVE Investment</div>
                  <div className="column suggestions pvp">PVP Investment</div>
                  <div className="column priority">Priority</div>
                </div>
                <div className="nikke-skills-row data">
                  <NikkeSkill char="mana" />
                </div>
              </div>
              <br />
              <h5>Cube Investments</h5>
              <StaticImage
                src="../../../images/nikke/generic/cube_3_sm.png"
                alt="Guide"
              />
              <StaticImage
                src="../../../images/nikke/generic/cube_4_sm.png"
                alt="Guide"
              />
              <p>
                Resilience or Bastion would probably be her best bet in PvE for
                that increased normal attack uptime. Use whichever cube has a
                higher level.
              </p>
              <StaticImage
                src="../../../images/nikke/generic/cube_11_sm.webp"
                alt="Guide"
              />
              <StaticImage
                src="../../../images/nikke/generic/cube_10_sm.png"
                alt="Guide"
              />
              <StaticImage
                src="../../../images/nikke/generic/cube_8_sm.png"
                alt="Guide"
              />
              <p>
                Meanwhile, in PvP, she can make use of Tempering, Healing, and
                Vigor cube pretty well.
              </p>
              <h5>Doll Investments</h5>
              <p>
                She is not worth too much doll investment seeing we just got a
                new batch of treasures. Prioritize meta or prevalent DPS units
                that have big damage or scale well with CP padding (PvP/Campaign
                units). SR 0 or R 5 → SR 5 then dip immediately.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="PvE Team Compositions" />
              <h5>Team #1: Windbringer</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="crown" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="scarlet-black-shadow"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="placeholder-box">Flex (Non SR/RL)</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="mana" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                A typical team against Wind-weak stages. This is just a
                variation of the old Alice - SBS team. Mana can act here as a
                non-burst B3 healer, or she can burst herself too because her
                damage can pack quite a punch against Wind-weak enemies.
                Nevertheless, her kit excels against single targets so Bursting
                with her on mob stages may not be the most ideal.{' '}
                <strong>Noir and S.Sakura are the top choices for FLEX</strong>.
              </p>
              <h5>Team #2: Alice Funnel</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="d-killer-wife"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="grave" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="alice" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="asuka-shikinami-langley"
                      enablePopover
                    />
                    <NikkeCharacter
                      mode="icon"
                      slug="rei-ayanami"
                      enablePopover
                    />
                    <NikkeCharacter mode="icon" slug="maxwell" enablePopover />
                  </div>
                  <div className="team-slot">
                    <h5 className="burst-3">Burst 3</h5>
                    <div className="employee-container for-nikke">
                      <NikkeCharacter mode="icon" slug="mana" enablePopover />
                    </div>
                  </div>
                </div>
              </div>
              <p>
                Let's just say you want a fixed team for Alice, and you don't
                have charge speed lines on Alice (like Rental Alice), what can
                you do? Well, good news. Mana can solve that issue!
              </p>
              <p>
                Mana not only provides pure healing to this team, but also
                provides Alice with enough Charge Speed to reach 100% (assuming
                10/x/10 on Alice, as well as Mana skill 2). Hence, this allows
                us to get 4× Ammo, 4× ATK and 4× ELE on our Alice! Just imagine
                how many bullets she can fire with Bastion Cube equipped, not to
                mention Liter and Grave also give Max Ammo. Furthermore, if an
                extra buffer is desired, Rei and Maxwell can boost Alice’s
                damage dealt. Or, you can just slap a second DPS like Asuka
                (which also buffs her). D:KW is also a good alternative to Liter
                as she can buff Alice’s Pierce Damage.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="PvP Team Compositions" />
              <h5>A Second Life</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="placeholder-box">Flex</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="placeholder-box">Flex</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="placeholder-box">Flex</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="mana" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="placeholder-box">Flex</div>
                </div>
              </div>
              <p>
                Mana allows your tanks or DPS to have a second life, which means
                they can die and be revived with up to 96% HP. This is useful
                for when your Biscuit is already occupied by other teams.
                Counterable by Rosanna, who can dispel her Metal γ. Example
                team: Blanc Scarlet Rumani Mana Centi (Scarlet and Blanc
                position swappable).
              </p>
              <p>
                Mana can also pair with B3 SBS, replacing Alice in this setup.
                The team SBS Blanc Centi Mana Jackal, for example, is an
                upgraded variant with better defense and speed.
              </p>
              <h5>The Ultimate Stall</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="moran" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="nero" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="helm-treasure"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="mana" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                This team utilizes Damage Reduction to its maximum potential by
                combining units who can trigger Nero's passive and benefit from
                her "payback". In this 3 RL comp, HelmTr, Rapunzel, and Mana
                continually heal the team while enjoying up to ~75% damage
                reduction buffs (dependent on investments, doll, and cube),
                whereas Moran and Nero tank for them. Nero can also gain ≥100%
                damage reduction here at maximum investments, meaning she will
                not take any damage at all for 10s after Full Burst! Not to
                mention, you are well-equipped with double revives, two Full
                Burst possibilities, charge speed bonus, and 10s lifesteal. That
                is some insane defense right there.
              </p>
              <p>
                Counterable by Scarlet stall teams or swift 2 RL teams involving
                Blanc + Rosanna to dispel Mana and counteract Damage Taken.
                Variants of this team include repositioning some units to let
                Rapunzel be the first B1 or to let Nero be the main tanker. Do
                note that Moran's Attract activates first, so she will be the
                one taunting primary attacks and Nero the secondary attacks as
                decoy (for SAnis's Ray, etc).
              </p>
              <h5>Double/Triple Sacrifice</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="placeholder-box">Flex</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="placeholder-box">B2</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="placeholder-box">B3</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rosanna" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="mana" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                In this composition, we place focus on our Rosanna and pray that
                she can disrupt the enemy comp by assassinating their key units.
                This comp requires you to have much higher sync than your
                opponent because it may come with massive CP reduction (if you
                plan to sacrifice unleveled units for better consistency). The
                idea of this comp is to let your P1 unit die, and then get
                revived, and then die again (and additionally sacrifice another
                unit, which can be Mana herself or another unit — the former
                requires putting Mana in exposed positions like P2), which will
                grant Rosanna 2 or 3 stacks of ATK and make her fill the burst
                bar by up to ~36.5% per death. Hell, this team might even B1 in
                1 RL!
              </p>
              <p>
                Counterable by opposing Rosanna, who can also abuse your units’
                deaths to generate Burst energy for them, essentially rivaling
                your team’s speed.
              </p>
              <p>
                Credit to SunburntVampire on Discord for coming up with this
                idea.
              </p>
            </div>
            <div className="section-scroll" id="section-6">
              <SectionHeader title="Pros & Cons" />
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          Finally saves Alice from being SBS’s permanent slave.
                        </li>
                        <li>
                          Instantaneous revival without bursting required.
                        </li>
                        <li>
                          Decent healing over time and can activate Nero’s and
                          Crown’s passive ability.
                        </li>
                        <li>Mama Mia… that MLB lobby screen panty shot!</li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          Her kits are very lackluster as a buffer in PVE.
                        </li>
                        <li>
                          Her own damage is mediocre and only optimal against a
                          single target.
                        </li>
                        <li>
                          Her S2 self-buff is weird; she has it on other-burst
                          rotations if used as primary B3, which means it does
                          not buff her nuke damage.
                        </li>
                        <li>
                          Her S2 self buffs are irrelevant if she is used as an
                          off-burst unit.
                        </li>
                        <li>Special Mention: Jien is hotter</li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5',
                'section-6'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Kit Analysis</a>
                </li>
                <li>
                  <a href="#section-2">Tier List</a>
                </li>
                <li>
                  <a href="#section-3">Investments</a>
                </li>
                <li>
                  <a href="#section-4">PVE Team Compositions</a>
                </li>
                <li>
                  <a href="#section-5">PVP Team Compositions</a>
                </li>
                <li>
                  <a href="#section-6">Pros & Cons</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesMana;

export const Head: React.FC = () => (
  <Seo
    title="Mana Guide & Review | NIKKE | Prydwen Institute"
    description="A guide & review for Mana in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
